<template lang="pug">div(v-else)
  form(@submit="onSubmit").password-renew
    img.logo(:src="logo")
    h1 {{ title }}

    .inner-content.login
      fpui-input-password(
        v-model="oldPassword"
        name="confirm_password"
        :placeholder="$t('authentication.password.old.placeholder')"
      )
        template(
          v-slot:label
        )
          label(:style="labelStyle") {{ $t('authentication.password.old') }}

      fpui-input-password-strength(
        v-model="newPassword"
        name="password"
        :helper-title="$t('authentication.password.helper.title')"
        :helper="passwordHelper"
        :error="!!isValidPassword.length && newPassword.length"
        :error-message="$t('authentication.password.error')"
        :placeholder="$t('authentication.password.placeholder')"
        :class="{ 'error': !isValidPassword }"
      )
        template(
          v-slot:label
        )
          label(:style="labelStyle") {{ $t('authentication.password.label') }}

      fpui-input-password(
        v-model="confirmPassword"
        name="confirm_password"
        :error="confirmPasswordError"
        :error-message="$t('authentication.confirm_password.error')"
        :placeholder="$t('authentication.confirm_password.placeholder')"
      )
        template(
          v-slot:label
        )
          label(:style="labelStyle") {{ $t('authentication.confirm_password.label') }}

      .actions
        fpui-button(
          type='submit'
          :style="buttonStyle"
          :disabled="!isReady()"
          color="custom"
          :button-style="buttonStyle"
        ) {{'authentication.submit'|translate}}
        .links
          a.cancel-link(
            @click="logout"
          ) {{'authentication.cancel'|translate}}
</template>

<script>
import zxcvbn from 'zxcvbn'
export default {
  filters: {
    check: function (status) {
      if (status) return 'fp4-check-bold'
      return 'fp4-xmark-bold'
    }
  },
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      score: 0
    }
  },
  computed: {
    labelStyle () {
      return {
        color: this.color
      }
    },
    logo () {
      const defaultLogo = require('@/shared/assets/img/ovhcloud_logo_blue.png')
      return this.$store.getters.PREFERENCES?.logo || defaultLogo || ''
    },
    title () {
      return this.$t('authentication.reset_password_title')
    },
    scoreClass () {
      return 'score-internal-' + this.score
    },
    nbInt () {
      let number = 0
      for (const i in this.newPassword) {
        if (!isNaN(this.newPassword.charAt(i))) number++
      }
      return number
    },
    nbSpecial () {
      let number = 0
      const specialChars = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
      for (const i in this.newPassword) {
        if (specialChars.indexOf(this.newPassword.charAt(i)) !== -1) number++
      }
      return number
    },
    nbCaps () {
      let number = 0
      for (const i in this.newPassword) {
        if (/[A-Z]/.test(this.newPassword.charAt(i))) number++
      }
      return number
    },
    passwordPolicies () {
      return {
        nb_char: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_char),
        nb_int: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_int),
        nb_schar: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_schar),
        nb_cchar: parseInt(this.$store.getters.PREFERENCES.password_policies.nb_cchar)
      }
    },
    isValidPassword () {
      const errors = []
      if (this.newPassword.length < this.passwordPolicies.nb_char) errors.push('not_enough_char')
      if (this.nbInt < this.passwordPolicies.nb_int) errors.push('not_enough_number')
      if (this.nbCaps < this.passwordPolicies.nb_cchar) errors.push('not_enough_caps')
      if (this.nbSpecial < this.passwordPolicies.nb_schar) errors.push('not_enough_special_char')
      return errors
    },
    passwordHelper () {
      return `<div>
        <ul class="password-helper-content">
          ${this.passwordPolicies.nb_char ? `<li><i class="fp4 ${this.icon(this.newPassword.length < this.passwordPolicies.nb_char)}"></i><span>${this.$t('authentication.password.helper.nbChar_minimum', [this.passwordPolicies.nb_char], this.passwordPolicies.nb_char)}</span></li>` : ''}
          ${this.passwordPolicies.nb_char ? `<li><i class="fp4 ${this.icon(this.nbCaps < this.passwordPolicies.nb_cchar)}"></i><span>${this.$t('authentication.password.helper.nbUpperCaseChar_minimum', [this.passwordPolicies.nb_cchar], this.passwordPolicies.nb_cchar)}</span></li>` : ''}
          ${this.passwordPolicies.nb_int ? `<li><i class="fp4 ${this.icon(this.nbInt < this.passwordPolicies.nb_int)}"></i><span>${this.$t('authentication.password.helper.nbNumber_minimum', [this.passwordPolicies.nb_int], this.passwordPolicies.nb_int)}</span></li>` : ''}
          ${this.passwordPolicies.nb_schar ? `<li><i class="fp4 ${this.icon(this.nbSpecial < this.passwordPolicies.nb_schar)}"></i><span>${this.$t('authentication.password.helper.nbSpecial_minimum', [this.passwordPolicies.nb_schar], this.passwordPolicies.nb_schar)}</span></li>` : ''}
        </ul>
      </div>`
    },
    passwordError () {
      if (this.newPassword.length === 0) return false
      const passwordRegexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/)
      return !passwordRegexp.test(this.newPassword)
    },
    confirmPasswordError () {
      if (this.confirmPassword.length === 0) return false
      return this.confirmPassword !== this.newPassword
    },
    color () { return this.$store.getters.PREFERENCES.color || '#00ccf9' },
    buttonStyle () {
      return {
        color: 'white', // TODO - Add a detect for light or dark background
        backgroundColor: this.color
      }
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('LOGOUT')
    },
    textRule (text, number) {
      if (number > 1) text += 's'
      return this.$t('authentication.password.rules.' + text, [number])
    },
    onPasswordChange () {
      this.score = zxcvbn(this.newPassword).score
    },
    async onSubmit ($event) {
      if (!this.isReady()) return
      $event.preventDefault()
      const { oldPassword, newPassword } = this
      try {
        await this.$store.dispatch('PASSWORD_RENEW', { oldPassword, newPassword })
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    },
    isReady () {
      return !this.isValidPassword.length && !this.confirmPasswordError
    },
    icon (ruleValue) {
      return !ruleValue ? 'fp4-check-bold' : 'fp4-xmark-bold'
    }
  }
}
</script>
<style lang="less">
.password-helper-content {
  text-align: left;
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    i.fp4-check-bold {
      color: @green;
    }
    i.fp4-xmark-bold {
      color: @red;
    }
    i {
      margin-right: 15px;
    }
  }
}

.password-renew {
  .logo {
    width: 150px;
  }
  h1 {
    margin-bottom: 0;
    line-height: 54px;
    padding-top: 15px;
    font-size: 54px;
  }

  p {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 300;
    max-width: 650px;
  }

  .login {
    max-width: 456px !important;
    margin-top: 10px;
    max-width: none;

    .fpui-input-text, .fpui-input-phone, .fpui-input-select-container {
      margin-top: 21px;
    }
    .fpui-input-label-container {
      text-transform: uppercase;
      color: #0089C0;
      font-weight: 600;
    }

    input {
      margin: 0;
      max-width: 456px;
    }

    .fpui-input-password {
      &.error {
        .fpui-helper {
          > i.fp4.text-default {
            color: @red !important;
          }
        }
      }
    }

    .actions {
      max-width: 456px;
      margin-top: 42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-container {
        border-radius: 5px;
        &.signup {
          width: 100%;
          .fpui-button {
            width: 100%;
          }
        }
      }
      .links {
        position: relative;
        a {
          color: #C7D0D8;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
